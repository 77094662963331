import axios from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import config from '../config';

import { IRubrique } from '../models/rubriqueInterface';

export interface RubriqueStoreInterface {
  
  failedFetchRubrique: boolean;
  isGettingInfo: boolean;
  allRubrique: IRubrique[];
  isLoading: boolean;
  rubrique: IRubrique | null;
  listSize: number;
  selectedRubrique: IRubrique | null;

  setRubrique: (rubrique: IRubrique | null) => void;
  getAllRubrique: () => Promise<any>;
  // getFilteredRubrique: (filter: Record<string, unknown>) => Promise<any>;
  createRubrique: (data: IRubrique) => void;
  updateRubrique: (data: IRubrique) => void;
  deleteRubrique: (data: IRubrique) => void;
  setSelectedRubrique: (data: IRubrique | null) => void;
}

class RubriqueStore implements RubriqueStoreInterface {

  @observable failedFetchRubrique = false;
  @observable isGettingInfo = false;
  @observable allRubrique: IRubrique[] = [];
  @observable isLoading = false;
  @observable rubrique: IRubrique | null = null;
  @observable selectedRubrique: IRubrique | null = null;
  @observable listSize = 0;

  constructor() {
    makeObservable(this);
  }

  @action setSelectedRubrique = (rubrique: IRubrique | null) => {
    this.selectedRubrique = rubrique;
  };

  @action setRubrique = (rub: IRubrique | null) => {
    this.rubrique = rub;
  };

  @action getAllRubrique = async () => {
    this.isLoading = true;
    try {
      const rubriques = await axios.get(`${config.servers.apiUrl}rubrique/getAllRubrique`);
      this.allRubrique = rubriques.data.allRubrique.rows;
      this.listSize = this.allRubrique.length;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };


  // @action getFilteredUser = async (filter: Record<string, unknown>) => {
  //   try {
  //     this.isLoading = true;
  //     const users = await axios.post(`${config.servers.apiUrl}user/filter`, filter);
  //     this.allUsers = users.data;
  //     this.isLoading = false;
  //   } catch (error) {
  //     console.error(error)
  //   } finally {
  //     this.isLoading = false;
  //   }
  // };

  @action createRubrique = async (data: IRubrique) => {
    try {
      const add = await axios.post(`${config.servers.apiUrl}rubrique`, data);
      return add;
    } catch (err) {
        console.error(err)
    }
  };

  @action updateRubrique = async (rubrique: IRubrique) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}rubrique/editRubrique`, rubrique);
      
    //   if (this.user?._id === userUpdate._id) {
    //     this.getUserInfo();
    //   }

      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };

  @action deleteRubrique = async (rubrique: IRubrique) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}deleteRubrique`, rubrique);
      // rootStore.updateSnackBar(true, 'Supprimé', 'success');
      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };
}

export default new RubriqueStore();
