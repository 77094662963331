import axios from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import config from '../config';
import { IUser } from '../models/userInterface';

export interface UserStoreInterface {
  
  isLoading: boolean;
  failedFetchUser: boolean;
  isGettingInfo: boolean;

  user: IUser | null;
  allUsers: IUser[];
  selectedUser: IUser | null;

  listSize: number;

  setUser: (user: IUser | null) => void;
  getUserInfo: () => void;
  updateUserInfo: (userUpdate: IUser & { newPassword?: string }) => void;
  getAllUser: () => Promise<any>;
  getFilteredUser: (filter: Record<string, unknown>) => Promise<any>;
  createUser: (data: IUser) => void;
  updateUser: (data: IUser) => void;
  deleteUser: (data: IUser) => void;
  setSelectedUser: (data: IUser | null) => void;
}

class UserStore implements UserStoreInterface {
  @observable isGettingInfo = false;

  @observable user: IUser | null = null;

  @observable selectedUser: IUser | null = null;

  @observable failedFetchUser = false;

  @observable userAdmin: IUser[] = [];

  @observable allUsers: IUser[] = [];

  @observable isLoading = false;

  @observable listSize = 0;

  constructor() {
    makeObservable(this);
  }

  @action setSelectedUser = (user: IUser | null) => {
    this.selectedUser = user;
  };

  @action getUserInfo = async () => {
    try {
      this.isGettingInfo = true;
      const me = await axios.get(`${config.servers.apiUrl}user/me`);
      this.isGettingInfo = false;
      if (me.data) {
        runInAction(() => {
          this.user = me.data.user as IUser;
        });
      }
    } catch (error) {
      this.isGettingInfo = false;
      this.failedFetchUser = true;
      // parseError(
      //   error,
      //   "Une erreur s'est produite lors de la requête de vos infos. Veuillez réessayer"
      // );
    }
  };

  @action setUser = (u: IUser | null) => {
    this.user = u;
  };

  @action updateUserInfo = async (userUpdate: IUser & { newPassword?: string | undefined }) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}user/${userUpdate.usr_id}`, userUpdate);
      if (user.data.ok) {
        this.getUserInfo();
        // rootStore.updateSnackBar(true, 'Enregistré', 'success');
      } 
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
      console.error(err)
    }
  };

  @action getAllUser = async () => {
    this.isLoading = true;
    try {
      const users = await axios.get(`${config.servers.apiUrl}user/getAllUser`);
      this.allUsers = users.data.allUsers.rows;
      this.listSize = this.allUsers.length;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };


  @action getFilteredUser = async (filter: Record<string, unknown>) => {
    try {
      this.isLoading = true;
      const users = await axios.post(`${config.servers.apiUrl}user/filter`, filter);
      this.allUsers = users.data;
      this.isLoading = false;
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  };

  @action createUser = async (data: IUser) => {
    try {
      const add = await axios.post(`${config.servers.apiUrl}user/signup`, data);
      return add;
    } catch (err) {
        console.error(err)
    }
  };

  @action updateUser = async (userUpdate: IUser) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}user/edit`, userUpdate);
      
    //   if (this.user?._id === userUpdate._id) {
    //     this.getUserInfo();
    //   }

      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };

  @action deleteUser = async (userDelete: IUser) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}user/delete`, userDelete);
      // rootStore.updateSnackBar(true, 'Supprimé', 'success');
      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };
}

export default new UserStore();
