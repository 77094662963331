import { CircularProgress } from '@mui/material';
import React,{ FC } from 'react';

import { AbstractEmptyInterface } from '../../../types';

const Loading: FC<AbstractEmptyInterface> = () => {
    return (
        <>
            <CircularProgress variant='determinate' color='primary'></CircularProgress>
        </>
    )
};

export default Loading;