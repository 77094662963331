import React,{ FC } from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import MainNav from '../mainNav/MainNav';

import { AbstractEmptyInterface } from '../../../types';
// import Copyright from '../copyright';

import useStyles from './style';

interface LayoutProps extends AbstractEmptyInterface {
    children:any;
};

const Layout: FC<AbstractEmptyInterface> = ( props) => {

    const {children} = props as LayoutProps;

    const classes = useStyles();

    return (
        <> 
            <Grid container={true} className={classes.container}>
                <Grid item={true} className={classes.mainNav}>
                    <MainNav></MainNav>
                </Grid>
                <Grid item ={true} className={classes.content}>
                    {children}
                </Grid>
            </Grid>
        </>
    )
};

export default Layout;
