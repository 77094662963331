import appStore from './AppStore'
import userStore from './UserStore'
import authStore from './AuthStore';
import searchStore from './SearchStore';
import reviewStore from './ReviewStore';
import thematicStore from './ThematicStore';
import rubriqueStore from './Rubrique';
import authorStore from './AuthorStore';

export default {
  userStore,
  authStore,
  searchStore,
  reviewStore,
  thematicStore,
  rubriqueStore,
  authorStore,
  appStore,
}