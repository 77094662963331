import React,{ ChangeEventHandler, FC, useState, Suspense } from 'react';
import {PathRouteProps, RouteProps, useNavigate} from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Outlet, Navigate } from 'react-router-dom';
import { UserStoreInterface } from '../../../stores/UserStore';
import AuthServices from '../../../services/AuthServices';
import Layout from '../layout';
import Loading from '../loading';


interface ProtectedRouteProps extends PathRouteProps { 
  access?: string[];
  userStore: UserStoreInterface;
};


const ProtectedRoute: FC<PathRouteProps & {access?: string[]}> = (props) => {

    const { access, userStore } = props as ProtectedRouteProps;

    const isLogged = AuthServices.isAuthenticated();
 
    if (userStore.isGettingInfo) {
      return <Loading />;
    }

    if (isLogged) {

      const user = userStore.user;

      let userRole = user ? user.usr_type : "";
      if (!access || access.includes( userRole )){
        return(
          <>        
          <Suspense fallback={<Loading />}>
            <Layout><Outlet/></Layout>
          </Suspense>          
          </>
        );
      }
      return (
        <Suspense fallback={<Loading />}>
            <Navigate to="/"/>
          </Suspense>         
      )
    }
    
    return (
      <Suspense fallback={<Loading />}>
          <Navigate to="/login"/>
       </Suspense> 
    )
};

export default inject('userStore')(observer(ProtectedRoute));
