import { action, makeObservable, observable } from 'mobx';

export interface SearchStoreInterface {
  searchFilter: string;
  selectFilter: string;
  switchFilter : string;

  setSearchFilter: (filter: string) => void;
  setSelectFilter: (filter: string) => void;
  setSwitchFilter: (filter: string) => void;
}

class SearchStore implements SearchStoreInterface {
  @observable searchFilter = '';

  @observable  selectFilter = '';

  @observable  switchFilter = '';

  constructor() {
    makeObservable(this);
  }

  @action setSearchFilter = async (filter: string) => {
    this.searchFilter = filter;
  };

  @action setSelectFilter = async (filter: string) => {
    this.selectFilter = filter;
  };

  @action setSwitchFilter = async (filter: string) => {
    this.switchFilter = filter;
  };
}

export default new SearchStore();