import axios from 'axios';
import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import config from '../config';
import { IThematic } from '../models/thematicInterface';

export interface ThematicStoreInterface {
  
  failedFetchRubrique: boolean;
  isGettingInfo: boolean;
  allThematic: IThematic[];
  isLoading: boolean;
  thematic: IThematic | null;
  listSize: number;
  selectedThematic: IThematic | null;

  setThematic: (thematic: IThematic | null) => void;
  getAllThematic: () => Promise<any>;
  // getFilteredThematic: (filter: Record<string, unknown>) => Promise<any>;
  createThematic: (data: IThematic) => void;
  updateThematic: (data: IThematic) => void;
  deleteThematic: (data: IThematic) => void;
  setSelectedThematic: (data: IThematic | null) => void;
}

class ThematicStore implements ThematicStoreInterface {

  @observable failedFetchRubrique = false;
  @observable isGettingInfo = false;
  @observable allThematic: IThematic[] = [];
  @observable isLoading = false;
  @observable thematic: IThematic | null = null;
  @observable selectedThematic: IThematic | null = null;
  @observable listSize = 0;

  constructor() {
    makeObservable(this);
  }

  @action setSelectedThematic = (thematic: IThematic | null) => {
    this.selectedThematic = thematic;
  };

  @action setThematic = (rub: IThematic | null) => {
    this.thematic = rub;
  };

  @action getAllThematic = async () => {
    this.isLoading = true;
    try {
      const res = await axios.get(`${config.servers.apiUrl}thematic/getAllThematic`);
      const newThematics = res.data.allThematic.rows.reduce((arr: IThematic[], curr: IThematic) => {
        const { thematics, ...info } = curr;
        return arr.concat([info, ...(thematics || [])])
      }, [])
      this.allThematic = newThematics;
      this.listSize = this.allThematic.length;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };


  // @action getFilteredUser = async (filter: Record<string, unknown>) => {
  //   try {
  //     this.isLoading = true;
  //     const users = await axios.post(`${config.servers.apiUrl}user/filter`, filter);
  //     this.allUsers = users.data;
  //     this.isLoading = false;
  //   } catch (error) {
  //     console.error(error)
  //   } finally {
  //     this.isLoading = false;
  //   }
  // };

  @action createThematic = async (data: IThematic) => {
    try {
      const add = await axios.post(`${config.servers.apiUrl}thematic`, data);
      return add;
    } catch (err) {
        console.error(err)
    }
  };

  @action updateThematic = async (thematic: IThematic) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}thematic/editThematic`, thematic);
      
    //   if (this.user?._id === userUpdate._id) {
    //     this.getUserInfo();
    //   }

      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };

  @action deleteThematic = async (thematic: IThematic) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}thematic/deleteThematic`, thematic);
      // rootStore.updateSnackBar(true, 'Supprimé', 'success');
      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };
}

export default new ThematicStore();
