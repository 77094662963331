import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme)=> ({
  container: {
    minwidth: '100vw',
    display: 'flex',
    flexDirection: 'row',   
  },

  mainNav : {
    width: '100%',
    height: '7vh',
  },

  content: {
    width: '100%',
    height: '89vh',
    marginTop:'1vh',
  },
}));

export default useStyles;
