import axios from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import config from '../config';
import { IAuthor } from '../models/authorInterface';

import { IRubrique } from '../models/rubriqueInterface';

export interface AuthorStoreInterface {
  
  isLoading: boolean;
  failedFetchRubrique: boolean;
  isGettingInfo: boolean;
  
  allAuthor: IAuthor[];
  author: IAuthor | null;
  listSize: number;
  selectedAuthor: IAuthor | null;

  setAuthor: (author: IAuthor | null) => void;
  getAllAuthor: () => Promise<any>;
  // getFilteredAuthor: (filter: Record<string, unknown>) => Promise<any>;
  createAuthor: (data: IAuthor) => void;
  updateAuthor: (data: IAuthor) => void;
  deleteAuthor: (data: IAuthor) => void;
  setSelectedAuthor: (data: IAuthor | null) => void;
}

class AuthorStore implements AuthorStoreInterface {

  @observable failedFetchRubrique = false;
  @observable isGettingInfo = false;
  @observable allAuthor: IAuthor[] = [];
  @observable isLoading = false;
  @observable author: IAuthor | null = null;
  @observable selectedAuthor: IAuthor | null = null;
  @observable listSize = 0;

  constructor() {
    makeObservable(this);
  }

  @action setSelectedAuthor = (author: IAuthor | null) => {
    this.selectedAuthor = author;
  };

  @action setAuthor = (author: IAuthor | null) => {
    this.author = author;
  };

  @action getAllAuthor = async () => {
    this.isLoading = true;
    try {
      const rubriques = await axios.get(`${config.servers.apiUrl}author/getAllAuthor`);
      this.allAuthor = rubriques.data.allAuthor.rows;
      this.listSize = this.allAuthor.length;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };


  // @action getFilteredUser = async (filter: Record<string, unknown>) => {
  //   try {
  //     this.isLoading = true;
  //     const users = await axios.post(`${config.servers.apiUrl}user/filter`, filter);
  //     this.allUsers = users.data;
  //     this.isLoading = false;
  //   } catch (error) {
  //     console.error(error)
  //   } finally {
  //     this.isLoading = false;
  //   }
  // };

  @action createAuthor = async (data: IAuthor) => {
    try {
      const add = await axios.post(`${config.servers.apiUrl}author`, data);
      return add;
    } catch (err) {
        console.error(err)
    }
  };

  @action updateAuthor = async (author: IAuthor) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}author/editAuthor/1`, author);
      
    //   if (this.user?._id === userUpdate._id) {
    //     this.getUserInfo();
    //   }

      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };

  @action deleteAuthor = async (author: IAuthor) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}author/deleteAuthor`, author);
      // rootStore.updateSnackBar(true, 'Supprimé', 'success');
      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };
}

export default new AuthorStore();
