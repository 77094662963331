import { createTheme } from "@mui/material";

const globalTheme = createTheme({
    palette: {
        primary:{
            main: '#B25800',
            dark: '#000000',
            light: '#0AB1E1',
        },
        secondary:{
            main: '#022545',
            dark: '#000000',
            light: '#0AB1E1',
        },
    },
    typography:{
        fontFamily:['Poppins','sans-cherif'].join(','),
        fontSize: 16,
        htmlFontSize: 16,
        h1:{
            fontFamily:['Poppins','sans-cherif'].join(','),
            fontSize: '2.1875rem',
            fontWeight: 700,
            textTransform: 'uppercase',
        },
    },
    

});
export default globalTheme;