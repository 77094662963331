import axios from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import config from '../config';
import { IReview } from '../models/reviewInterface';

export interface ReviewStoreInterface {
  
  failedFetchReview: boolean;
  isGettingInfo: boolean;
  allReview: IReview[];
  isLoading: boolean;
  review: IReview | null;
  listSize: number;
  selectedReview: IReview | null;

  setReview: (review: IReview | null) => void;
  getAllReview: () => Promise<any>;
  // getFilteredReview: (filter: Record<string, unknown>) => Promise<any>;
  createReview: (data: IReview) => void;
  updateReview: (data: IReview) => void;
  deleteReview: (data: IReview) => void;
  setSelectedReview: (data: IReview | null) => void;
}

class ReviewStore implements ReviewStoreInterface {

  @observable failedFetchReview = false;
  @observable isGettingInfo = false;
  @observable allReview: IReview[] = [];
  @observable isLoading = false;
  @observable review: IReview | null = null;
  @observable selectedReview: IReview | null = null;
  @observable listSize = 0;

  constructor() {
    makeObservable(this);
  }

  @action setSelectedReview = (review: IReview | null) => {
    this.selectedReview = review;
  };


  @action setReview = (rev: IReview | null) => {
    this.review = rev;
  };


  @action getAllReview = async () => {
    this.isLoading = true;
    try {
      const reviews = await axios.get(`${config.servers.apiUrl}review/getAllReview`);
console.log('revue', reviews);


      this.allReview = reviews.data.allReview.rows;
      this.listSize = this.allReview.length;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };


  // @action getFilteredUser = async (filter: Record<string, unknown>) => {
  //   try {
  //     this.isLoading = true;
  //     const users = await axios.post(`${config.servers.apiUrl}user/filter`, filter);
  //     this.allUsers = users.data;
  //     this.isLoading = false;
  //   } catch (error) {
  //     console.error(error)
  //   } finally {
  //     this.isLoading = false;
  //   }
  // };

  @action createReview = async (data: IReview) => {
    try {
      const add = await axios.post(`${config.servers.apiUrl}review`, data);
      return add;
    } catch (err) {
        console.error(err)
    }
  };

  @action updateReview = async (review: IReview) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}review/editReview`, review);
      
    //   if (this.user?._id === userUpdate._id) {
    //     this.getUserInfo();
    //   }

      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };

  @action deleteReview = async (review: IReview) => {
    try {
      const user = await axios.patch(`${config.servers.apiUrl}review/deleteReview`, review);
      // rootStore.updateSnackBar(true, 'Supprimé', 'success');
      return user;
    } catch (err) {
      // parseError(err, {
      //   404: "L'utilisateur demandé est introuvable",
      //   403: 'Vous ne pouvez pas effectuer cette opération ou le mot de passe entré est incorrect',
      // });
    }
  };
}

export default new ReviewStore();
