import React,{ FC } from 'react';
import { Snackbar } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { AppStoreInterface } from '../../../stores/AppStore';
import { AbstractEmptyInterface } from '../../../types';
import Alert from '../Alert';
import useStyles from './style';

interface SnackbarProps extends AbstractEmptyInterface {
  appStore: AppStoreInterface;
}

const CustomizedSnackbar: FC<AbstractEmptyInterface> = props => {
  const { appStore } = props as SnackbarProps;
  const classes = useStyles();

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    appStore.updateSnackBar(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={appStore.snackBarState.open}
        autoHideDuration={appStore.snackBarState.duration}
        onClose={handleClose}>
          <div>
            <Alert onClose={handleClose} severity={appStore.snackBarState.severity}>
              {appStore.snackBarState.message}
            </Alert>
          </div>
      </Snackbar>
    </div>
  );
};

export default inject('appStore')(observer(CustomizedSnackbar));
