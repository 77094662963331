export interface AbstractEmptyInterface {}
export type SnackBarSeverity = 'success' | 'warning' | 'info' | 'error';

export const allUsers = ['user', 'admin'];

export const defaultUser = {
  usr_id:"",
  usr_firstname:"",
  usr_lastname: "",
  usr_username: "",
  usr_email: "",
  usr_phone: "",
  usr_password: "",
  usr_address: "",
  usr_type: "",
  deleted: false,
  usr_active: false
}
