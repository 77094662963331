import axios from 'axios';
import React,{ lazy} from 'react';
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import ProtectedRoute from '../components/common/protectedRoute';
import AuthServices from '../services/AuthServices';
import { allUsers } from '../types';


const Login = lazy(() => import("../components/user/login/Login")); 
const ListUser = lazy(() => import("../components/user/list/ListUser")); 
const Dashboard = lazy(() => import("../components/dashboard/dashboard")); 
const ListReview = lazy(() => import("../components/review/list"));
const ListRubrique = lazy(() => import("../components/rubrique/list/"));
const ListThematic = lazy(() => import("../components/thematic/list"));
const ListAuthor = lazy(() => import("../components/author/list"));

let signOutTime: any = 0;

const signOut = () => {
    AuthServices.setAccessToken("");
    AuthServices.signOut();
};

const startTimer = () => {
  signOutTime = setTimeout(signOut, 1800000);
};

const Router = () => {

  axios.interceptors.response.use((resp) => {
    const { token } = resp.headers;
    if (token !== "" && token !== undefined) {
      if (signOutTime === 0 || signOutTime === undefined) {
        startTimer();
      } else {
        clearTimeout(signOutTime);
        startTimer();
      }
      AuthServices.setAccessToken(token);
    }
    return resp;
  });
  
  return (
    <Routes>
      <Route path="/" element={<Navigate replace  to="/login" />}/>
      <Route path="/login" element={<Login/>}/>
      /** dashboard */        
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/dashboard" element={<Dashboard/>}/>
      </Route>
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/user" element={<ListUser/>}/> 
      </Route>  
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/review" element={<ListReview/>}/> 
      </Route>
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/thematic" element={<ListThematic/>}/> 
      </Route>
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/rubrique" element={<ListRubrique/>}/> 
      </Route>
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/author" element={<ListAuthor/>}/> 
      </Route>
    </Routes>
  )
}

export default Router;